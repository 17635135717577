import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Denna veckan tittar vi på nya Ubuntu, Edge på Linux och Git. Vi diskuterar även GitHubs kontroversiella nedstängning av youtube-dl och huruvida det finns andra alternativ. Detta och mycket mer, häng med!`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Ubuntu 20.10 ute `}<a parentName="li" {...{
          "href": "https://discourse.ubuntu.com/t/groovy-gorilla-release-notes/15533"
        }}>{`https://discourse.ubuntu.com/t/groovy-gorilla-release-notes/15533`}</a></li>
      <li parentName="ul">{`Alex har installerat och gått över till Edge... och han älskar det: `}<a parentName="li" {...{
          "href": "https://www.microsoftedgeinsider.com/en-us/"
        }}>{`https://www.microsoftedgeinsider.com/en-us/`}</a></li>
      <li parentName="ul">{`Firefox 82: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/82.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/82.0/releasenotes/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`youtube-dl nedtagen från GitHub? `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linux/comments/jgubfx/youtubedl_github_repo_taken_down_due_to_dmca/"
        }}>{`https://www.reddit.com/r/linux/comments/jgubfx/youtubedl_github_repo_taken_down_due_to_dmca/`}</a>{` `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linux/comments/jhs4on/interview_with_philhag_exmaintainer_of_youtubedl/"
        }}>{`https://www.reddit.com/r/linux/comments/jhs4on/interview_with_philhag_exmaintainer_of_youtubedl/`}</a>{` `}<a parentName="li" {...{
          "href": "https://blog.codeberg.org/on-the-youtube-dl-dmca-takedown.html"
        }}>{`https://blog.codeberg.org/on-the-youtube-dl-dmca-takedown.html`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`git över ssh`}</li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Hur Seb satte upp sin Mastodon-instans`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/wimvanderbauwhede/limited-systems/wiki/A-simple-model-for-network-data-size-and-disk-space-usage-of-a-fediverse-instance"
            }}>{`https://github.com/wimvanderbauwhede/limited-systems/wiki/A-simple-model-for-network-data-size-and-disk-space-usage-of-a-fediverse-instance`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      